<template>
  <div>
    <div class="row">
      <div class="col-4">
        <b-card title="Lesson Average CEFR">
          <b-table striped
                   hover
                   :items="averageCefr"
                   :fields="fields"
          >
            <template #cell(cefr)="data">
              {{ number_format(data.item.cefr, 2) }}
            </template>
          </b-table>
          <div class="my-2">
            <b-button :disabled="isProcessing"
                      variant="primary"
                      @click="saveLessonCalibrations"
            >
              Update
            </b-button>
          </div>
          <b-card-footer>
            <p>
              If you update the Weighage of a lesson <b>{{ lesson?.name }}</b>, the changes will take effect immediately in the students' CEFR rate
            </p>
          </b-card-footer>
        </b-card>
      </div>
      <div class="col-8">
        <b-button
          variant="danger"
          class="mb-2"
          @click="syncCefrLesson"
        >
          Reset
        </b-button>
        <table class="table">
          <thead>
            <tr>
              <th />
              <th>Classroom</th>
              <th>Reading</th>
              <th>Listening</th>
              <th>Writing</th>
              <th>Speaking</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="calibration,index of perStoredCalibration"
                :key="index"
            >
              <td>
                <b-form-checkbox-group
                  id="checkbox-group"
                  v-model="selected"
                >
                  <b-form-checkbox :value="calibration.id"
                                   selected
                  />
                </b-form-checkbox-group>
              </td>
              <td>
                {{ calibration.classRoom?.name }}
              </td>
              <td>
                {{ calibration.average_reading }}
              </td>
              <td>
                {{ calibration.average_listening }}
              </td>
              <td>
                {{ calibration.average_writing }}
              </td>
              <td>
                {{ calibration.average_speaking }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, BCardFooter, BFormCheckbox,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard, BTable, BButton, BCardFooter, BFormCheckbox,
  },
  props: {
    lessonId: {
      type: Number,
      default: 0,
    },
    averageCefr: {
      type: Array,
      default: () => [],
    },
    lesson: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isProcessing: false,
      fields: [
        { key: 'skill', label: 'Skill' },
        { key: 'cefr', label: 'Cefr' },
      ],
      perStoredCalibration: [],
      selected: [],
    }
  },
  mounted() {
    this.getCefrStatusOfLesson()
  },
  methods: {
    saveLessonCalibrations() {
      this.isProcessing = true
      useJwt.updateStudentLessonCalibration(this.lessonId).then(response => {
        this.showSuccessMessage(response)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getCefrStatusOfLesson() {
      useJwt.getCefrStatusOfLesson(this.lessonId).then(response => {
        this.$emit('updateAverageCefr', response.data?.average_calibrations)
        this.perStoredCalibration = response.data?.lesson_calibration
        this.selected = response.data?.lesson_calibration.map(item => item.id)
      })
    },
    syncCefrLesson() {
      useJwt.updateCefrStatusOfLesson(this.lessonId, {
        required_lesson_ids: this.perStoredCalibration,
      }).then(response => {
        this.showSuccessMessage(response)
      })
    },
  },
}
</script>
